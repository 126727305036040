

    var ori3_login = new Vue({
      el: "#ori3-login",
      data: {
        needemail: true,
        code: "",
        email: "",
        token: "",
        errorstr: "",
        remember: true
      },
      methods: {
        loginemail: function() {
          this.errorstr = "";
          axios.post("/api/v1/login/email", {
              email: this.email
            })
            .then(res => {
              if (res.data.error != undefined) {
                this.errorstr = res.data.error;
              } else {
                this.token = res.data.token;
                if (this.token !== undefined) {
                  this.needemail = false;
                }
              }
            })
            .catch((reterror) => {
              if (reterror.response) {
                this.errorstr = reterror.response.data.error;
              } else {
                this.errorstr = reterror.message;
              }
            })
        },
        logincode: function() {
          this.errorstr = "";
          axios.post("/api/v1/login/code", {
              token: this.token,
              code: this.code,
              remember: this.remember
            })
            .then(res => {
              if (res.data.error != undefined) {
                this.errorstr = res.data.error;
              } else {
                change_page("main.html")
              }
            })
            .catch((reterror) => {
              if (reterror.response) {
                this.errorstr = reterror.response.data.error;
              } else {
                this.errorstr = reterror.message;
              }
            })
        }
      },
      directives: {
        focus: {
          inserted: function(el) {
            el.focus()
          }
        }
      }
    });


  /*
  function auth_google() {
      googleyolo.hint({
        supportedAuthMethods: [
          "https://accounts.google.com"
        ],
        supportedIdTokenProviders: [
          {
            uri: "https://accounts.google.com",
            clientId: localStorage.getItem('google_client_id')
          }
        ]
      }).then((credential) => {
        if (credential.idToken) {
          console.log(credential.idToken);
          console.log(credential);
          localStorage.setItem('user_fullname', credential.displayName);
          localStorage.setItem('user_picture', credential.profilePicture);
          localStorage.setItem('user_email', credential.id);
          var a = { 'tokenid' : credential.idToken };
          $.post('/api/authgoogletoken', JSON.stringify(a))
           .done(function(r) {
              if (r.error == undefined) {
                  change_page("main.html");
              }
           });
        }
      });
  }


  */


  // function auth_facebook (userid, token, name, email)
  // {
  //   var a = { 'userid' : userid , 'token' : token};
  //   $.post("/api/authfacebook", JSON.stringify(a))
  //    .done(function(r) {
  //      if (r.error == undefined) {
  //         localStorage.setItem('user_fullname', name);
  //         localStorage.setItem('user_email',  email);
  //         change_page("main.html");
  //      }
  //    });
  // }


$(function() {
    axios.get("/api/v1/me").then(res => {
      change_page("main.html");
    })
    // $.get("/api/authinfo")
    //  .done(function(r) {
    //    if (r.error == undefined) {
    //      localStorage.setItem('google_client_id', r.google_client_id);
    //    }
    //  });


  });